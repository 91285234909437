<nav class="navbar navbar-expand-lg navbar-light bg-light">
    <div class="container-fluid">
        <div class="logo">
            <a routerLink="/"><h3>Tokenize</h3></a>
        </div>

        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav">
                <li class="nav-item"><a  href="/" class="nav-link">Acceuil</a></li>
                <li class="nav-item"><a  href="#concept" class="nav-link">Concept</a></li>
                <li class="nav-item"><a href="#offre" class="nav-link">Offres</a></li>
                <li class="nav-item"><a  href="#application" class="nav-link">Application</a></li>
                <li class="nav-item"><a href="#contact" class="nav-link">Contact</a></li>

            </ul>

            <div class="others-option">
                <div class="d-flex align-items-center">
                    <div class="option-item">
                        <a routerLink="/sign-in" class="default-btn">Se Connecter<span></span></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</nav>
