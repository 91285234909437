<section class="footer-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-sm-6">
                <div class="single-footer-widget">
                    <a routerLink="/" class="logo"><h2>Description</h2></a>
                    <p>Tokenize</p>
                    <ul class="social-list">
                        <li><a href="#" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                        <li><a href="#" target="_blank"><i class="fab fa-twitter"></i></a></li>
                        <li><a href="#" target="_blank"><i class="fab fa-linkedin-in"></i></a></li>
                        <li><a href="#" target="_blank"><i class="fab fa-instagram"></i></a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-footer-widget pl-5">
                    <h3>Tokenize</h3>
                    <ul class="list">
                        <li><a routerLink="/">About</a></li>
                        <li><a routerLink="/">Description</a></li>
                        <li><a routerLink="/">Programme</a></li>
                        <li><a routerLink="/">Login</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Produits</h3>
                    <ul class="list">
                        <li><a routerLink="/">Integrations</a></li>
                        <li><a routerLink="/">Documentation</a></li>
                        <li><a routerLink="/">Release Notes</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Télecharger</h3>
                    <ul class="footer-holder">
                        <li><a href="#"><img src="assets/landing/img/store/1.png" alt="image"></a></li>
                        <li><a href="#"><img src="assets/landing/img/store/2.png" alt="image"></a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>

<div class="copy-right">
    <div class="container">
        <div class="copy-right-content">
            <p>
                <i class="far fa-copyright"></i> All Rights Reserved by Tokenize</p>
        </div>
    </div>
</div>

<div class="go-top">
    <i class="fa fa-chevron-up"></i>
    <i class="fa fa-chevron-up"></i>
</div>
