<div class="container">

    <br>
    <br>
    <br>

    <div class="row align-items-center">
        <div class="col-lg-6 col-md-12">
            <div class="about-content">
                <h3>Notre processus est simple !</h3>
                <div class="bar"></div>
                <p>1) Inscrivez-vous gratuitement et profitez de tous nos services , Les abonnements peuvent atteindre jusqu'à -50% de réduction.</p>
                <p>2) Trouvez votre partenaire le plus proche de vous.</p>
                <p>3) Choisissez votre offre et achetez depuis le platform ou le revendeur.</p>
                <p>4) Partagez vos token avec votre amis ! Vous pouvez désormais transférer les Tokens entre vous.</p>
            </div>
        </div>

<!--        <div class="col-lg-6 col-md-12">-->
<!--            <div class="about-image">-->
<!--                <img src="assets/landing/img/overview.png" alt="image">-->
<!--            </div>-->
<!--        </div>-->
    </div>
</div>
