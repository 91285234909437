<app-preloader></app-preloader>
<app-navbar></app-navbar>
<div  class="main-banner">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container-fluid">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-12">
                        <div class="banner-content">
                            <h1>Tokenize ! Votre abonnement virtuelle</h1>
                            <p>TOUT PRES DE CHEZ VOUS !</p>
                            <!-- <div class="banner-holder">
                                <a routerLink="/"><img src="assets/landing/img/store/1.png" alt="image"></a>
                                <a routerLink="/"><img src="assets/landing/img/store/2.png" alt="image"></a>
                            </div> -->
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-12">
                        <div class="banner-image">
                            <img src="assets/landing/img/token2.png" alt="image">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="default-shape" >
        <div class="shape-1"><img src="assets/landing/img/shape/1.png" alt="image"></div>
        <div class="shape-2 rotateme"><img src="assets/landing/img/shape/2.png" alt="image"></div>
        <div class="shape-3"><img src="assets/landing/img/shape/3.svg" alt="image"></div>
        <div class="shape-4"><img src="assets/landing/img/shape/4.svg" alt="image"></div>
        <div class="shape-5"><img src="assets/landing/img/shape/5.png" alt="image"></div>
    </div>
</div>

<section class="fun-facts-area pt-70 pb-70">
    <app-funfacts></app-funfacts>
</section>

<section id="concept" class="about-area pb-20">
    <app-about></app-about>
</section>

<section id="offre" class="features-area pb-60">
    <app-features></app-features>

    <div class="default-shape">
        <div class="shape-1"><img src="assets/landing/img/shape/1.png" alt="image"></div>
        <div class="shape-2 rotateme"><img src="assets/landing/img/shape/2.png" alt="image"></div>
        <div class="shape-3"><img src="assets/landing/img/shape/3.svg" alt="image"></div>
        <div class="shape-4"><img src="assets/landing/img/shape/4.svg" alt="image"></div>
        <div class="shape-5"><img src="assets/landing/img/shape/5.png" alt="image"></div>
    </div>
</section>

<section id="application">
    <app-app-screenshots></app-app-screenshots>
</section>

<!-- <section class="testimonials-area ptb-100">
    <app-testimonials></app-testimonials>

    <div class="default-shape">
        <div class="shape-1"><img src="assets/landing/img/shape/1.png" alt="image"></div>
        <div class="shape-2 rotateme"><img src="assets/landing/img/shape/2.png" alt="image"></div>
        <div class="shape-3"><img src="assets/landing/img/shape/3.svg" alt="image"></div>
        <div class="shape-4"><img src="assets/landing/img/shape/4.svg" alt="image"></div>
        <div class="shape-5"><img src="assets/landing/img/shape/5.png" alt="image"></div>
    </div>
</section> -->

<!-- <section class="overview-area ptb-100">
    <app-overview></app-overview>
</section> -->

<!--<section class="pricing-area pt-50 pb-70">-->
<!--    <app-pricing></app-pricing>-->

<!--    <div class="default-shape">-->
<!--        <div class="shape-1"><img src="assets/landing/img/shape/1.png" alt="image"></div>-->
<!--        <div class="shape-2 rotateme"><img src="assets/landing/img/shape/2.png" alt="image"></div>-->
<!--        <div class="shape-3"><img src="assets/landing/img/shape/3.svg" alt="image"></div>-->
<!--        <div class="shape-4"><img src="assets/landing/img/shape/4.svg" alt="image"></div>-->
<!--        <div class="shape-5"><img src="assets/landing/img/shape/5.png" alt="image"></div>-->
<!--    </div>-->
<!--</section>-->

<!-- <section id="faq" class="faq-area ptb-100">
    <app-faq></app-faq>

    <div class="default-shape">
        <div class="shape-1"><img src="assets/landing/img/shape/1.png" alt="image"></div>
        <div class="shape-2 rotateme"><img src="assets/landing/img/shape/2.png" alt="image"></div>
        <div class="shape-3"><img src="assets/landing/img/shape/3.svg" alt="image"></div>
        <div class="shape-4"><img src="assets/landing/img/shape/4.svg" alt="image"></div>
        <div class="shape-5"><img src="assets/landing/img/shape/5.png" alt="image"></div>
    </div>
</section>

<section class="team-area pt-100 pb-70">
    <app-team></app-team>
</section> -->

<!-- <app-app-download></app-app-download> -->

<!-- <section id="blog" class="blog-area pt-100 pb-70">
    <app-blog></app-blog>
</section> -->

<section id="contact" class="contact-area pt-20 pb-10">
    <app-contact></app-contact>

    <div class="default-shape">
        <div class="shape-1"><img src="assets/landing/img/shape/1.png" alt="image"></div>
        <div class="shape-2 rotateme"><img src="assets/landing/img/shape/2.png" alt="image"></div>
        <div class="shape-3"><img src="assets/landing/img/shape/3.svg" alt="image"></div>
        <div class="shape-4"><img src="assets/landing/img/shape/4.svg" alt="image"></div>
        <div class="shape-5"><img src="assets/landing/img/shape/5.png" alt="image"></div>
    </div>
</section>

<!-- <app-subscribe></app-subscribe> -->

<app-footer></app-footer>
