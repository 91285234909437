/* tslint:disable:max-line-length */
import { FuseNavigationItem } from '@fuse/components/navigation';

export const defaultNavigation: FuseNavigationItem[] = [
    {
        id: 'dashboards',
        title: 'Dashboards',
        subtitle: 'Statistical analysis',
        type: 'group',
        icon: 'heroicons_outline:home',
        children: [
            {
                id: 'dashboards.project',
                title: 'Home',
                type: 'basic',
                icon: 'heroicons_outline:clipboard-check',
                link: '/project'
            },
            {
                id: 'dashboards.analytics',
                title: 'Analytics',
                type: 'basic',
                icon: 'heroicons_outline:chart-pie',
                link: '/analytics'
            },
        ]
    },
    {
        id: 'users',
        title: 'Users',
        subtitle: 'User management',
        type: 'group',
        icon: 'heroicons_outline:home',
        children: [
            {
                id: 'apps.academy',
                title: 'Groups',
                type: 'basic',
                icon: 'heroicons_outline:academic-cap',
                link: '/groups'
            },
            {
                id: 'apps.contacts',
                title: 'Customer management',
                type: 'basic',
                icon: 'heroicons_outline:user-group',
                link: '/contacts'
            },
            {
                id: 'apps.academy',
                title: 'Partner management',
                type: 'basic',
                icon: 'heroicons_outline:academic-cap',
                link: '/partner-management'
            },
            {
                id: 'apps.ecommerce',
                title: 'Partner categories',
                type: 'basic',
                icon: 'heroicons_outline:shopping-cart',
                link: '/partner',
            },
            {
                id: 'apps.academy',
                title: 'Dealer',
                type: 'basic',
                icon: 'heroicons_outline:academic-cap',
                link: '/dealer'
            },
            {
                id: 'apps.scrumboard',
                title: 'Company',
                type: 'basic',
                icon: 'heroicons_outline:view-boards',
                link: '/company'
            },
        ]
    },

    {
        id: 'content',
        title: 'Content',
        subtitle: 'Content management',
        type: 'group',
        icon: 'heroicons_outline:home',
        children: [
            {
                id: 'apps.academy',
                title: 'Product Categories',
                type: 'basic',
                icon: 'heroicons_outline:academic-cap',
                link: '/product-categories'
            },
            {
                id: 'apps.contacts',
                title: 'Products',
                type: 'basic',
                icon: 'heroicons_outline:user-group',
                link: '/products'
            },
           
        ]
    },

    {
        id: 'settings',
        title: 'Accountant',
        subtitle: 'Financial Management',
        type: 'group',
        icon: 'heroicons_outline:document',
        children: [
            {
                id: 'pages.pricing',
                title: 'Offer',
                type: 'basic',
                icon: 'heroicons_outline:cash',
                link: '/offer'
            },
            
            {
                id: 'apps.file-manager',
                title: 'Inventory',
                type: 'basic',
                icon: 'heroicons_outline:cloud',
                link: '/inventory'
            },
            {
                id: 'apps.file-manager',
                title: 'Incomes',
                type: 'basic',
                icon: 'heroicons_outline:cloud',
                link: '/incomes'
            },
        ]
    },
    {
        id: 'settings',
        title: 'Settings',
        subtitle: 'General settings',
        type: 'group',
        icon: 'heroicons_outline:document',
        children: [
            
            {
                id: 'pages.profile',
                title: 'Profile',
                type: 'basic',
                icon: 'heroicons_outline:user-circle',
                link: '/profile'
            },
        
        ]
    },
];

export const partnerNavigation: FuseNavigationItem[] = [


    {
        id: 'users',
        title: 'Transaction',
        subtitle: 'Transaction management',
        type: 'group',
        icon: 'heroicons_outline:home',
        children: [
           
            {
                id: 'apps.academy',
                title: 'Wallet',
                type: 'basic',
                icon: 'heroicons_outline:academic-cap',
                link: '/user-wallet'
            },
            {
                id: 'apps.academy',
                title: 'Transactions',
                type: 'basic',
                icon: 'heroicons_outline:academic-cap',
                link: '/user-transactions'
            },
           
            {
                id: 'apps.academy',
                title: 'Payouts',
                type: 'basic',
                icon: 'heroicons_outline:academic-cap',
                link: '/user-payouts'
            },
            {
                id: 'apps.academy',
                title: 'Offer Purchases',
                type: 'basic',
                icon: 'heroicons_outline:academic-cap',
                link: '/user-offer-purchases'
            },
        
        ]
    },
    {
        id: 'users',
        title: 'Inventory',
        subtitle: 'Inventory management',
        type: 'group',
        icon: 'heroicons_outline:home',
        children: [
            {
                id: 'apps.contacts',
                title: 'Product categories',
                type: 'basic',
                icon: 'heroicons_outline:user-group',
                link: '/partner-product-categories'
            },
            {
                id: 'apps.academy',
                title: 'Products',
                type: 'basic',
                icon: 'heroicons_outline:academic-cap',
                link: '/partner-products'
            },
         
          
        
        ]
    },
    {
        id: 'settings',
        title: 'Settings',
        subtitle: 'General settings',
        type: 'group',
        icon: 'heroicons_outline:document',
        children: [
            {
                id: 'pages.pricing',
                title: 'My Offers',
                type: 'basic',
                icon: 'heroicons_outline:cash',
                link: '/user-offers'
            },
            {
                id: 'pages.profile',
                title: 'Account Settings',
                type: 'basic',
                icon: 'heroicons_outline:user-circle',
                link: '/settings'
            },
           
        ]
    },
];

export const dealerNavigation: FuseNavigationItem[] = [


    {
        id: 'users',
        title: 'Transaction',
        subtitle: 'Transaction management',
        type: 'group',
        icon: 'heroicons_outline:home',
        children: [
           
            {
                id: 'apps.academy',
                title: 'Wallet',
                type: 'basic',
                icon: 'heroicons_outline:academic-cap',
                link: '/user-wallet'
            },
        
           
            {
                id: 'apps.academy',
                title: 'Payouts',
                type: 'basic',
                icon: 'heroicons_outline:academic-cap',
                link: '/user-payouts'
            },
            {
                id: 'apps.academy',
                title: 'Offer Purchases',
                type: 'basic',
                icon: 'heroicons_outline:academic-cap',
                link: '/user-offer-purchases'
            },
        
        ]
    },
   
    {
        id: 'settings',
        title: 'Settings',
        subtitle: 'General settings',
        type: 'group',
        icon: 'heroicons_outline:document',
        children: [
            {
                id: 'pages.pricing',
                title: 'My Offers',
                type: 'basic',
                icon: 'heroicons_outline:cash',
                link: '/user-offers'
            },
          
           
        ]
    },
];
export const compactNavigation: FuseNavigationItem[] = [
    {
        id   : 'example',
        title: 'Example',
        type : 'basic',
        icon : 'heroicons_outline:chart-pie',
        link : '/example'
    }
];
export const futuristicNavigation: FuseNavigationItem[] = [
    {
        id   : 'example',
        title: 'Example',
        type : 'basic',
        icon : 'heroicons_outline:chart-pie',
        link : '/example'
    }
];
export const horizontalNavigation: FuseNavigationItem[] = [
    {
        id   : 'example',
        title: 'Example',
        type : 'basic',
        icon : 'heroicons_outline:chart-pie',
        link : '/example'
    }
];
