<div class="container">
    <div class="row align-items-center">
        <div class="col-lg-3 col-sm-6">
            <div class="single-fun-fact">
                <div class="icon">
                    <i class="fa fa-users"></i>
                </div>
                <h3><span>{{data.customerCount}}</span></h3>
                <p>Utilisateurs</p>
            </div>
        </div>
        <div class="col-lg-3 col-sm-6">
            <div class="single-fun-fact">
                <div class="icon">
                    <i class="fa fa-download"></i>
                </div>
                <h3><span>{{data.partnerCount}}</span></h3>
                <p>Partenaires</p>
            </div>
        </div>
        <div class="col-lg-3 col-sm-6">
            <div class="single-fun-fact">
                <div class="icon">
                    <i class="fa fa-star"></i>
                </div>
                <h3><span>{{data.companyCount}}</span></h3>
                <p>Société</p>
            </div>
        </div>

        <div class="col-lg-3 col-sm-6">
            <div class="single-fun-fact">
                <div class="icon">
                    <i class="fa fa-star"></i>
                </div>
                <h3><span>{{data.dealerCount}}</span></h3>
                <p>Revendeur</p>
            </div>
        </div>
    </div>
</div>
