<div class="container">
    <div class="section-title">
        <h2>Nos Prix</h2>
        <div class="bar"></div>
        <p>..</p>
    </div>

    <div class="tab pricing-list-tab">
        <ul class="tabs">
            <li><a href="#">Par mois</a></li>
            <li><a href="#">Par année</a></li>
        </ul>

        <div class="tab_content">
            <div class="tabs_item">
                <div class="row">
                    <div class="col-lg-4 col-md-6">
                        <div class="single-pricing-table">
                            <div class="pricing-header">
                                <h3>Standard</h3>
                            </div>

                            <div class="price">
                                <sup>$</sup>33 <sub>/ moi</sub>
                            </div>

                            <ul class="pricing-features">
                                <li><i class="fas fa-check"></i> ..</li>
                                <li><i class="fas fa-check"></i> ..</li>
                                <li><i class="fas fa-check"></i> ..</li>
                                <li><i class="fas fa-check"></i> ..</li>
                                <li><i class="fa fa-times"></i> ..</li>
                                <li><i class="fa fa-times"></i> ..</li>
                                <li><i class="fa fa-times"></i> ..</li>
                            </ul>

                            <div class="pricing-btn">
                                <a routerLink="/" class="default-btn">Acheter <span></span></a>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6">
                        <div class="single-pricing-table">
                            <div class="pricing-header">
                                <h3>Personal</h3>
                            </div>

                            <div class="price">
                                <sup>$</sup>69 <sub>/ moi</sub>
                            </div>

                            <ul class="pricing-features">
                                <li><i class="fas fa-check"></i> ..</li>
                                <li><i class="fas fa-check"></i> ..</li>
                                <li><i class="fas fa-check"></i> ..</li>
                                <li><i class="fas fa-check"></i> ..</li>
                                <li><i class="fas fa-check"></i> ..</li>
                                <li><i class="fa fa-times"></i> ..</li>
                                <li><i class="fa fa-times"></i> ..</li>
                            </ul>

                            <div class="pricing-btn">
                                <a routerLink="/" class="default-btn">Acheter <span></span></a>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6">
                        <div class="single-pricing-table">
                            <div class="pricing-header">
                                <h3>Business</h3>
                            </div>

                            <div class="price">
                                <sup>$</sup>99 <sub>/ moi</sub>
                            </div>

                            <ul class="pricing-features">
                                <li><i class="fas fa-check"></i> ..</li>
                                <li><i class="fas fa-check"></i> ..</li>
                                <li><i class="fas fa-check"></i> ..</li>
                                <li><i class="fas fa-check"></i> ..</li>
                                <li><i class="fas fa-check"></i> ..</li>
                                <li><i class="fas fa-check"></i> ..</li>
                                <li><i class="fa fa-times"></i> ..</li>
                            </ul>

                            <div class="pricing-btn">
                                <a routerLink="/" class="default-btn">Acheter <span></span></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="tabs_item">
                <div class="row">
                    <div class="col-lg-4 col-md-6">
                        <div class="single-pricing-table">
                            <div class="pricing-header">
                                <h3>Standard</h3>
                            </div>

                            <div class="price">
                                <sup>$</sup>33 <sub>/ année</sub>
                            </div>

                            <ul class="pricing-features">
                                <li><i class="fas fa-check"></i> ..</li>
                                <li><i class="fas fa-check"></i> ..</li>
                                <li><i class="fas fa-check"></i> ..</li>
                                <li><i class="fas fa-check"></i> ..</li>
                                <li><i class="fas fa-check"></i> ..</li>
                                <li><i class="fa fa-times"></i> ..</li>
                                <li><i class="fa fa-times"></i> ..</li>
                            </ul>

                            <div class="pricing-btn">
                                <a routerLink="/" class="default-btn">Acheter <span></span></a>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6">
                        <div class="single-pricing-table">
                            <div class="pricing-header">
                                <h3>Personal</h3>
                            </div>

                            <div class="price">
                                <sup>$</sup>69 <sub>/ année</sub>
                            </div>

                            <ul class="pricing-features">
                                <li><i class="fas fa-check"></i> ..</li>
                                <li><i class="fas fa-check"></i> ..</li>
                                <li><i class="fas fa-check"></i> ..</li>
                                <li><i class="fas fa-check"></i> ..</li>
                                <li><i class="fas fa-check"></i> ..</li>
                                <li><i class="fas fa-check"></i> ..</li>
                                <li><i class="fa fa-times"></i> ..</li>
                            </ul>

                            <div class="pricing-btn">
                                <a routerLink="/" class="default-btn">Acheter <span></span></a>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6">
                        <div class="single-pricing-table">
                            <div class="pricing-header">
                                <h3>Business</h3>
                            </div>

                            <div class="price">
                                <sup>$</sup>99 <sub>/ année</sub>
                            </div>

                            <ul class="pricing-features">
                                <li><i class="fas fa-check"></i> ..</li>
                                <li><i class="fas fa-check"></i> ..</li>
                                <li><i class="fas fa-check"></i> ..</li>
                                <li><i class="fas fa-check"></i> ..</li>
                                <li><i class="fas fa-check"></i> ..</li>
                                <li><i class="fas fa-check"></i> ..</li>
                                <li><i class="fas fa-check"></i> ..</li>
                            </ul>

                            <div class="pricing-btn">
                                <a routerLink="/" class="default-btn">Acheter <span></span></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>