import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ExtraOptions, PreloadAllModules, RouterModule } from '@angular/router';
import { MarkdownModule } from 'ngx-markdown';
import { FuseModule } from '@fuse';
import { FuseConfigModule } from '@fuse/services/config';
import { FuseMockApiModule } from '@fuse/lib/mock-api';
import { CoreModule } from 'app/core/core.module';
import { appConfig } from 'app/core/config/app.config';
import { mockApiServices } from 'app/mock-api';
import { LayoutModule } from 'app/layout/layout.module';
import { AppComponent } from 'app/app.component';
import { appRoutes } from 'app/app.routing';
import { PreloaderComponent } from './modules/landing/common/preloader/preloader.component';
import { FooterComponent } from './modules/landing/common/footer/footer.component';
import { NavbarComponent } from './modules/landing/common/navbar/navbar.component';
import { SubscribeComponent } from './modules/landing/common/subscribe/subscribe.component';
import { ContactComponent } from './modules/landing/common/contact/contact.component';
import { BlogPageComponent } from './modules/landing/pages/blog-page/blog-page.component';
import { BlogDetailsComponent } from './modules/landing/pages/blog-details/blog-details.component';
import { HowItWorksComponent } from './modules/landing/common/how-it-works/how-it-works.component';
import { FunfactsComponent } from './modules/landing/common/funfacts/funfacts.component';
import { AboutComponent } from './modules/landing/common/about/about.component';
import { FeaturesComponent } from './modules/landing/common/features/features.component';
import { AppScreenshotsComponent } from './modules/landing/common/app-screenshots/app-screenshots.component';
import { TestimonialsComponent } from './modules/landing/common/testimonials/testimonials.component';
import { OverviewComponent } from './modules/landing/common/overview/overview.component';
import { PricingComponent } from './modules/landing/common/pricing/pricing.component';
import { FaqComponent } from './modules/landing/common/faq/faq.component';
import { TeamComponent } from './modules/landing/common/team/team.component';
import { AppDownloadComponent } from './modules/landing/common/app-download/app-download.component';
import { BlogComponent } from './modules/landing/common/blog/blog.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';

import { HomeOneComponent } from './modules/landing/pages/home-one/home-one.component';

const routerConfig: ExtraOptions = {
    useHash: true ,
    preloadingStrategy: PreloadAllModules,
    scrollPositionRestoration: 'enabled'
};

@NgModule({
    declarations: [
        AppComponent,
        PreloaderComponent,
        FooterComponent,
        NavbarComponent,
        SubscribeComponent,
        HomeOneComponent,
        ContactComponent,
        BlogComponent,
        AppDownloadComponent,
        TeamComponent,
        FaqComponent,
        PricingComponent,
        OverviewComponent,
        TestimonialsComponent,
        AppScreenshotsComponent,
        FeaturesComponent,
        AboutComponent,
        FunfactsComponent,
        HowItWorksComponent,
        BlogDetailsComponent,
        BlogPageComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        RouterModule.forRoot(appRoutes, routerConfig),

        // Fuse, FuseConfig & FuseMockAPI
        FuseModule,
        FuseConfigModule.forRoot(appConfig),
        FuseMockApiModule.forRoot(mockApiServices),

        // Core module of your application
        CoreModule,
        ToastrModule.forRoot(),
        // Layout module of your application
        LayoutModule,

        // 3rd party modules that require global configuration via forRoot
        MarkdownModule.forRoot({})
    ],
    providers: [

    ],
    bootstrap: [
        AppComponent
    ]

})
export class AppModule {
}
