import { Component, OnInit } from '@angular/core';
import { OffreService } from '../../pages/home-one/home.service';

@Component({
  selector: 'app-features',
  templateUrl: './features.component.html',
  styleUrls: ['./features.component.scss']
})
export class FeaturesComponent implements OnInit {

    offerList: any[];

  constructor(private service: OffreService) {}

  ngOnInit(): void {
    this.service.getOffer()
        .subscribe((response: any) => {
          console.log(response);
          this.offerList = response.data;
        });
  }
}
