<div class="container">
    <div class="section-title">
        <br><br>
        <h2>Nos Offres</h2>
        <div class="bar"></div>
    </div>
    <div class="container">
    <div class="row">
        <div class="col">
            <div class="single-features" *ngFor="let offre of offerList">
<!--                <div class="icon">-->
<!--                    <i class="fa fa-sun"></i>-->
<!--                </div>-->
                <h3>{{ offre.name }}</h3>
                <h5>{{ offre.price }} TND</h5>
                <h7>{{ offre.jetonAmount }} Token</h7>
                <p>{{ offre.description }}</p>

            </div>
        </div>
    </div>


    </div>
</div>
