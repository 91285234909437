<section id="screenshots" class="screenshot-area ptb-100">
    <div class="container-fluid">
        <div class="section-title">
            <h2>Notre Application</h2>
            <div class="bar"></div>
            <p>..</p>
        </div>

        <div class="screenshot-slider owl-carousel owl-theme">
            <div class="screenshot-item">
                <div class="image">
                    <img src="assets/landing/img/screenshot/1.jpeg" alt="image">
                </div>
            </div>

            <div class="screenshot-item">
                <div class="image">
                    <img src="assets/landing/img/screenshot/2.jpeg" alt="image">
                </div>
            </div>

            <div class="screenshot-item">
                <div class="image">
                    <img src="assets/landing/img/screenshot/3.jpeg" alt="image">
                </div>
            </div>

            <div class="screenshot-item">
                <div class="image">
                    <img src="assets/landing/img/screenshot/4.jpeg" alt="image">
                </div>
            </div>

            <div class="screenshot-item">
                <div class="image">
                    <img src="assets/landing/img/screenshot/5.jpeg" alt="image">
                </div>
            </div>

            <div class="screenshot-item">
                <div class="image">
                    <img src="assets/landing/img/screenshot/6.jpeg" alt="image">
                </div>
            </div>

            <div class="screenshot-item">
                <div class="image">
                    <img src="assets/landing/img/screenshot/7.jpeg" alt="image">
                </div>
            </div>

            <div class="screenshot-item">
                <div class="image">
                    <img src="assets/landing/img/screenshot/8.jpeg" alt="image">
                </div>
            </div>

        </div>
    </div>
</section>
