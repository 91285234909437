export class Constant {

    // public static baseUrl = 'http://3.69.242.227:3002/api/v1';
    public static baseUrl = 'https://api-tokenize.lakontessa.com/api/v1';

    public static serverUrl = 'https://api-tokenize.lakontessa.com/';

    public static authUrl = Constant.baseUrl + '/auth/admin';

    public static partnerProductUrl = Constant.baseUrl + '/partner-products';


    public static backOfficeProductUrl = Constant.baseUrl + '/back-office-products';


    public static partnerWalletUrl = Constant.baseUrl + '/partner-wallets';

    public static partnerPayoutUrl = Constant.baseUrl + '/partner-payouts';

    public static partnerStatisticsUrl = Constant.baseUrl + '/partner-statistics';

    public static partnerOfferUrl = Constant.baseUrl + '/partner-offers';
    public static partnerTransactionUrl = Constant.baseUrl + '/partner-transactions';

    public static partnerOfferPurchaseUrl = Constant.baseUrl + '/partner-offer-purchases';

    public static authUrlPartner = Constant.baseUrl + '/auth/partner';

    public static authUrlDealer = Constant.baseUrl + '/auth/dealer';

    public static dealerUrl = Constant.baseUrl + '/dealers';
    public static groupUrl = Constant.baseUrl + '/back-office-groups';
    public static statsUrl = Constant.baseUrl + '/back-office-statistics';
    public static stocksUrl = Constant.baseUrl + '/back-office-stocks';

    public static incomeUrl = Constant.baseUrl + '/back-office-incomes';

    public static partnerUrl = Constant.baseUrl + '/back-office-partners';

    public static dealersUrl = Constant.baseUrl + '/back-office-dealers';

    public static dealerUrlPassword = Constant.baseUrl + '/dealers/password';
    public static dealerUrlActivate = Constant.baseUrl + '/dealers/activate';
    public static dealerUrlDeactivate = Constant.baseUrl + '/dealers/deactivate';
    public static dealerUrlSearch = Constant.baseUrl + '/dealers/search';



    public static companyUrl = Constant.baseUrl + '/companies';

    public static companyUrlPassword = Constant.baseUrl + '/companies/password';
    public static companyUrlActivate = Constant.baseUrl + '/companies/activate';
    public static companyUrlDeactivate = Constant.baseUrl + '/companies/deactivate';
    public static companyUrlSearch = Constant.baseUrl + '/companies/search';



    public static partnerCategoryUrl = Constant.baseUrl + '/back-office-partner-categories';
    public static partnerUrlPassword = Constant.baseUrl + '/partners/password';
    public static partnerUrlActivate = Constant.baseUrl + '/partners/activate';
    public static partnerUrlDeactivate = Constant.baseUrl + '/partners/deactivate';
    public static partnerUrlSearch = Constant.baseUrl + '/partners/search';


    public static partnerDetailsUrl = Constant.baseUrl + '/partners/mine';

    public static customerUrl = Constant.baseUrl + '/customers';
    public static customerUrlActivate = Constant.baseUrl + '/customers/activate';
    public static customerUrlDeactivate = Constant.baseUrl + '/customers/deactivate';
    public static customerUrlSearch = Constant.baseUrl + '/customers/search';



    public static offerUrl = Constant.baseUrl + '/offers';
    public static backOfficeOfferUrl = Constant.baseUrl + '/back-office-offers'
    public static offerUrlActivate = Constant.baseUrl + '/offers/activate';
    public static offerUrlDeactivate = Constant.baseUrl + '/offers/deactivate';
    public static offerUrlSearch = Constant.baseUrl + '/offers/search';


    public static userDetails = Constant.baseUrl + '/users/mine';
    public static userPassword = Constant.baseUrl + '/users/mine/password';

    public static platformDetails = Constant.baseUrl + '/platform-config';


    public static tokenUrl = Constant.baseUrl + '/tokens';
    public static tokenGenerateUrl = Constant.baseUrl + '/tokens/generate';


    public static uploadFileUrl = Constant.baseUrl + '/files/upload';





}
