import { Component, OnInit } from '@angular/core';
import {OffreService} from '../../pages/home-one/home.service';

@Component({
  selector: 'app-funfacts',
  templateUrl: './funfacts.component.html',
  styleUrls: ['./funfacts.component.scss']
})
export class FunfactsComponent implements OnInit {
  data: any;
  constructor(private service: OffreService) { }

  ngOnInit(): void {
    this.service.getStat()
        .subscribe((response: any) => {
          console.log(response);
          this.data = response.data;
        });
  }

}
