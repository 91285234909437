import { Route } from '@angular/router';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { NoAuthGuard } from 'app/core/auth/guards/noAuth.guard';
import { LayoutComponent } from 'app/layout/layout.component';
import { InitialDataResolver } from 'app/app.resolvers';
import { PartnerProductCategoriesModule } from './modules/admin/partner-product-categories/partner-product-categories.module';
import { HomeOneComponent } from './modules/landing/pages/home-one/home-one.component';


// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [

    // Redirect empty path to '/example'
    { path: '', pathMatch: 'full', redirectTo: 'project' },

    // Redirect signed in user to the '/example'
    //
    // After the user signs in, the sign in page will redirect the user to the 'signed-in-redirect'
    // path. Below is another redirection for that path to redirect the user to the desired
    // location. This is a small convenience to keep all main routes together here on this file.
    { path: 'signed-in-redirect', pathMatch: 'full', redirectTo: 'example' },

    // Auth routes for guests
    {
        path: '',
        canActivate: [NoAuthGuard],
        canActivateChild: [NoAuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            { path: 'confirmation-required', loadChildren: () => import('app/modules/auth/confirmation-required/confirmation-required.module').then(m => m.AuthConfirmationRequiredModule) },
            { path: 'forgot-password', loadChildren: () => import('app/modules/auth/forgot-password/forgot-password.module').then(m => m.AuthForgotPasswordModule) },
            { path: 'reset-password', loadChildren: () => import('app/modules/auth/reset-password/reset-password.module').then(m => m.AuthResetPasswordModule) },
            { path: 'sign-in', loadChildren: () => import('app/modules/auth/sign-in/sign-in.module').then(m => m.AuthSignInModule) },
        ]
    },

    // Auth routes for authenticated users
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            { path: 'sign-out', loadChildren: () => import('app/modules/auth/sign-out/sign-out.module').then(m => m.AuthSignOutModule) },
            { path: 'unlock-session', loadChildren: () => import('app/modules/auth/unlock-session/unlock-session.module').then(m => m.AuthUnlockSessionModule) }
        ]
    },

    // Landing routes
    {
        path: 'home',
        component: HomeOneComponent,
        data: {
            layout: 'empty'
        },
        children: [
            { path: 'home', component: HomeOneComponent },
        ]
    },

    // Admin routes
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: InitialDataResolver,
        },
        children: [
            { path: 'project', loadChildren: () => import('app/modules/admin/project/project.module').then(m => m.ProjectModule) },
            { path: 'analytics', loadChildren: () => import('app/modules/admin/analytics/analytics.module').then(m => m.AnalyticsModule) },
            { path: 'settings', loadChildren: () => import('app/modules/admin/settings/settings.module').then(m => m.SettingsModule) },
            { path: 'contacts', loadChildren: () => import('app/modules/admin/contacts/contacts.module').then(m => m.ContactsModule) },
            { path: 'partner', loadChildren: () => import('app/modules/admin/partner/partner.module').then(m => m.PartnerModule) },
            { path: 'dealer', loadChildren: () => import('app/modules/admin/dealer/dealer.module').then(m => m.DealerModule) },
            { path: 'company', loadChildren: () => import('app/modules/admin/company/company.module').then(m => m.CompanyModule) },
            { path: 'groups', loadChildren: () => import('app/modules/admin/groups/groups.module').then(m => m.GroupsModule) },
            { path: 'inventory', loadChildren: () => import('app/modules/admin/inventory/inventory.module').then(m => m.InventoryModule) },
            { path: 'offer', loadChildren: () => import('app/modules/admin/offer/offer.module').then(m => m.OfferModule) },
            { path: 'profile', loadChildren: () => import('app/modules/admin/profile/profile.module').then(m => m.ProfileModule) },

            {
                path: 'partner-management', loadChildren: () => import('app/modules/admin/partner-management/partner-management.module').then(m => m.PartnerManagementModule)
            },
            {
                path: 'partner-products', loadChildren: () => import('app/modules/admin/partner-products/partner-products.module').then(m => m.PartnerProductsModule)
            },
            {
                path: 'products', loadChildren: () => import('app/modules/admin/products/products.module').then(m => m.ProductsModule)
            },
            {
                path: 'user-offers', loadChildren: () => import('app/modules/admin/partner-offers/partner-offers.module').then(m => m.PartnerOffersModule)
            },
            {
                path: 'user-wallet', loadChildren: () => import('app/modules/admin/partner-wallet/partner-wallet.module').then(m => m.PartnerWalletModule)
            },
            {
                path: 'partner-product-categories', loadChildren: () => import('app/modules/admin/partner-product-categories/partner-product-categories.module').then(m => m.PartnerProductCategoriesModule)
            },
            {
                path: 'product-categories', loadChildren: () => import('app/modules/admin/product-categories/product-categories.module').then(m => m.ProductCategoriesModule)
            },

            {
                path: 'user-offer-purchases', loadChildren: () => import('app/modules/admin/partner-offer-purchases/partner-offer-purchases.module').then(m => m.PartnerOfferPurchasesModule)
            },

            {
                path: 'user-transactions', loadChildren: () => import('app/modules/admin/partner-transactions/partner-transactions.module').then(m => m.PartnerTransactionsModule)
            },

            {
                path: 'user-payouts', loadChildren: () => import('app/modules/admin/partner-payouts/partner-payouts.module').then(m => m.PartnerPayoutsModule)
            },

            {
                path: 'incomes', loadChildren: () => import('app/modules/admin/incomes/incomes.module').then(m => m.IncomesModule)
            },
        ]
    }
];
