import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Constant} from "../../../../constant";

@Injectable({
  providedIn: 'root'
})
export class OffreService {
  private url = Constant.baseUrl+'/offers/public';
  private stat = Constant.baseUrl+'/back-office-statistics/users';

  constructor(private httpClient: HttpClient) { }

  getOffer(){
    return this.httpClient.get(this.url);
  }

  getStat(){
    return this.httpClient.get(this.stat);
  }
}
